import React from 'react';
import { AxiosRequestConfig } from 'axios';
import { ChakraProvider } from '@contact/ui-volta';
import {
  ConfigVersion,
  useConfig,
  useFeatureFlags,
} from '@contact/data-access-hooks';
import { Maintenance } from '../Maintenance/Maintenance';
import { ContactEnergyTheme } from '../theme';

export interface MaintenanceProviderProps {
  environment: string;
  axiosOptions?: AxiosRequestConfig;
  children: React.Component;
}

export const MaintenanceProvider = ({
  environment,
  axiosOptions,
  children,
}: MaintenanceProviderProps) => {
  const { data, isSuccess } = useFeatureFlags(
    'v1',
    environment,
    undefined,
    undefined,
    axiosOptions
  );

  return isSuccess && data?.['maintenance-feature'] ? (
    <ChakraProvider theme={ContactEnergyTheme}>
      <Maintenance />
    </ChakraProvider>
  ) : (
    children
  );
};

export interface MaintenanceSimpleProviderProps {
  version: ConfigVersion;
  application: string;
  profile: string;
  children: React.Component;
}

export const MaintenanceSimpleProvider = ({
  version,
  application,
  profile,
  children,
}: MaintenanceSimpleProviderProps) => {
  const { data } = useConfig(version, application, profile);

  return data?.['MaintenanceFeatureFlag'] ? (
    <ChakraProvider theme={ContactEnergyTheme}>
      <Maintenance />
    </ChakraProvider>
  ) : (
    children
  );
};
