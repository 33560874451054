export const Namespace = 'TrimesterUploadDocumentTranslations';

export default {
  [Namespace]: {
    translations: {
      title: 'Fourth Trimester document upload',
      header: 'Upload your documents',
      errorNoDocumentSelected: 'Please select at least one file to be uploaded',
      button: 'Upload',
      documentUploadError: {
        header: 'Sorry, something went wrong',
        text: 'Your documents cannot be uploaded now. Please try again later',
      },
      sidebar: {
        title: 'Your baby’s documents',
        intro:
          "Here are the documents you can use to confirm your baby's birth date:",
        list: [
          'Hospital discharge notice',
          'Plunket Well Child Health Book (first page)',
          "Confirmation of baby's IRD number letter",
          'Adoption order letter',
          'Oranga Tamariki custody letter/guardianship letter',
        ],
      },
      ineligible: {
        title: 'Something has gone wrong',
        alert: 'This link is no longer valid or has expired.',
        contact: 'Please contact us on ',
        phone: 'tel:0800 245 245',
        link: ' for a new link.',
      },
    },
  },
};
