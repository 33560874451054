import moment from 'moment-timezone';
import {
  nzTimeZone,
  trimesterEndDate,
  trimesterStartDate,
} from '../Config/Constants';

export const isValidBabyDueDate = (
  value: moment.MomentInput,
  isCsr: boolean
) => {
  if (!value) {
    // Invalid input
    return false;
  }

  const today = moment().tz(nzTimeZone).startOf('day');

  const dateProvided = moment.tz(value, nzTimeZone);
  if (!dateProvided.isValid()) {
    // Invalid input
    return false;
  }
  if (dateProvided.isAfter(today, 'day')) {
    // Baby must be already born
    return false;
  }

  // Customer specific checks
  if (!isCsr) {
    if (today.isBefore(trimesterStartDate)) {
      // Trimester program not started yet
      return false;
    }
    if (dateProvided.isBefore(trimesterStartDate)) {
      // Baby born before trimester program start date
      return false;
    }
    if (dateProvided.isAfter(trimesterEndDate)) {
      // Baby born after trimester program end date
      return false;
    }
  }

  return true;
};

export const getBool = (value) => {
  if (value) {
    return !!JSON.parse(String(value).toLowerCase());
  }
};
